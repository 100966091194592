import * as React from 'react';

// @mui
import { Container, Typography } from '@mui/material';
// hooks
import useSettings from '../hooks/useSettings';
// components
import DashboardLayout from '../layouts/dashboard';

import Page from '../components/Page';
import {PoolOverview} from "../sections";

// ----------------------------------------------------------------------

export default function PoolsPage() {
    const { themeStretch } = useSettings();

    return (
        <DashboardLayout>
            <Page title="Pools">
                <Container maxWidth={themeStretch ? false : 'xl'}>
                    <PoolOverview/>
                </Container>
            </Page>
        </DashboardLayout>
    );
}
